<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="''">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
			
					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds" style="min-width: 180px;max-width: 500px;" mode="multiple" @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row v-show="showAll">
					<a-form-item name="userName" class="ui-form__item" label="会员昵称">
						<a-input v-model:value="formState.userName" placeholder="请输入会员昵称"></a-input>
					</a-form-item>
					
					<a-form-item name="userPhone" class="ui-form__item" label="会员手机">
						<a-input v-model:value="formState.userPhone" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
					
					<a-form-item name="isAfterSale" class="ui-form__item" label="是否售后">
						<a-select v-model:value="formState.isAfterSale" placeholder="请选择" style="width: 190px;">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="付款时间">
						<a-range-picker v-model:value="payTime" :disabledDate="disabledDate"/>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
			           <span v-permission="['report_revenue_code_export']">
							<ExportReport type="scanPayReport" :searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<a-table style="margin-top: 10px;" rowKey="id" :columns="columns" :dataSource="list" :pagination="pagination" :scroll="{ x: 1800 }">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'date'">
						{{ transDateTime(record.payTime , 1) }}
					</template>
					
					<template v-if="column.key === 'week'">
						星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.payTime *1000).day()] }}
					</template>
						
					<template v-if="column.key === 'time'">
						{{ transDateTime(record.payTime) }}
					</template>
					
					<template v-if="column.key === 'userInfo'">
						<div>用户昵称：{{ record.userName || '--' }}</div>
						<div>用户手机：{{ record.userPhone || '--' }}</div>
					</template>
					
					<template v-if="column.key === 'isAfterSale'">
						<div><a-tag :color="['blue','red'][record.isAfterSale]">{{ record.isAfterSale ? '已售后' : '未退款' }}</a-tag></div>
						<div v-if="record.isAfterSale">
							<div>退款金额：{{ record.refund }}元</div>
							<div>退款备注：{{ record.refundRemark }}</div>
							<div>操作人：{{ record.operationName }}</div>
							<div>操作时间：{{ transDateTime(record.refundTime) }}</div>
						</div>
					</template>
					
					<template v-if="column.key === 'action'">
						<a-button v-permission="['report_revenue_code_refund']" v-if="record.isAfterSale === 0" type="link" @click="onRefund(record)">申请售后</a-button>
					</template>
				</template>
				
				<template #summary v-if="list && list.length">
					<a-table-summary-row>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.payment || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.refund || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.realPay || 0 }}
						</a-table-summary-cell>
					</a-table-summary-row>
				</template>
			</a-table>

			<a-modal v-model:visible="refundVisible" title="退款" @cancel="onRefundCancel" @ok="onRefundOk">
				<a-spin :spinning="loading">
					<a-form ref="refundForm" name="refundForm" :model="refundData">
						<a-form-item label="退款金额" name="refund" extra="注:退款金额不允许大于实际付款金额" :rules="[{required: true, message: '请输入退款金额'}]">
							<a-input-number v-model:value="refundData.refund" :min="0" :max="detail.payment" placeholder="请输入">
							</a-input-number>
						</a-form-item>

						<a-form-item label="退款备注" name="remark" :rules="[{required: true, message: '请输入退款备注'}]">
							<a-textarea v-model:value="refundData.remark" placeholder="请输入退款备注"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import { scanReport, scanRefund } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	export default {
		components: {Icon, ExportReport},
		data(){
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				payTime: [],
				showTime: [],
				formState: {
					//organizationId: '',
					cinemaIds: [0],
					isAfterSale: -1
				},
				searchData: {},
				list:[],
				columns: [{
					title: '日期',
					key: 'date',
					width: 130
				}, {
					title: '星期',
					key: 'week',
					width: 100
				}, {
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '付款时间',
					key:'time',
				}, {
					title: '付款金额',
					dataIndex: 'payment',
					width: 150
				}, {
					title: '售后金额',
					dataIndex: 'refund',
					width: 150
				}, {
					title: '实付金额',
					dataIndex: 'realPay',
					width: 150
				}, {
					title: '售后信息',
					key: 'isAfterSale'
				}, {
					title: '会员信息',
					key: 'userInfo',
					width: 230
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				total: {},
				refundVisible: false,
				refundData: {},
				detail: {}
			}
		},
		created(){
			this.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
			//this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				if(this.searchData.cinemaIds && this.searchData.cinemaIds.length && this.searchData.cinemaIds.indexOf(0) === -1) {
					this.searchData.cinemaIds = this.searchData.cinemaIds.join(',');
				} else {
					this.searchData.cinemaIds = undefined;
				}
				if (this.searchData.isAfterSale === -1) {
					delete this.searchData.isAfterSale;
				}
				if (this.payTime && this.payTime.length) {
					this.searchData.payStartTime = this.moment(this.payTime[0].startOf('day')).unix();
					this.searchData.payEndTime = this.moment(this.payTime[1].endOf('day')).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.searchData = {};
				this.formState.cinemaIds = [0];
				this.payTime = [];
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.pagination.total = 0;
				this.list = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await scanReport({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.total = ret.data.list[0];
						this.list = ret.data.list.splice(1);
					}
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onRefund(item) {
				this.detail = item;
				this.refundData = {};
				this.refundVisible = true;
			},
			onRefundCancel() {
				this.$refs.refundForm.resetFields();
				this.refundVisible = false;
				this.detail = {};
				this.refundData = {};
			},
			onRefundOk() {
				this.$refs.refundForm.validateFields().then(() => {
					this.$confirm({
						title:'提示',
						content: '确定需要退款吗？',
						onOk: async ()=> {
							this.loading = true;
							try {
								let ret = await scanRefund({
									id: this.detail.id,
									refund: this.refundData.refund,
									remark: this.refundData.remark
								});
								this.loading = false;
								if (ret.code === 200) {
									this.$message.success('退款操作成功');
									this.refundVisible = false;
									this.getData();
								}
							} catch(e) {
								this.loading = false;
								console.log(e)
							}
						}
					})
				});
			},
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			disabledDate(current) {
			    return current && current > this.moment().endOf('day');
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
